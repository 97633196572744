<template>
  <div class="app">
    <AppHeader fixed>
      <b-link class="header-logo" to="/">
        <img class="navbar-brand-full mx-4" src="/img/logo.png" width="180" height="58" alt="Logo" />
      </b-link>
      <b-navbar-nav v-show="this.user != null" class="fl mr-4">
        <b-nav-item v-show="this.level == 9" class="px-3" to="/admin/users">회원관리 </b-nav-item>
        <b-nav-item class="px-3" v-show="this.level == 6 || this.level == 9" to="/admin/distributors"
          >총판관리</b-nav-item
        >
        <b-nav-item class="px-3" v-show="this.level == 9" to="/payment/history">충전관리</b-nav-item>
        <b-nav-item class="px-3" v-show="this.level == 9" to="/admin/recommendCodes">코드관리</b-nav-item>
        <b-nav-item class="px-3" v-show="this.level == 9" to="/admin/banList">차단관리</b-nav-item>
        <b-nav-item class="px-3" v-show="this.level == 9" to="/admin/dumps">덤프관리</b-nav-item>
        <b-nav-item class="px-3" to="/smsSend">발송</b-nav-item>
        <b-nav-item class="px-3" to="/smsSendTest">3사테스트</b-nav-item>
        <b-nav-item class="px-3" to="/SmsRequests">결과</b-nav-item>
        <b-nav-item class="px-3" to="/payment" exact>충전</b-nav-item>
        <b-nav-item class="px-3" to="/mypage" exact>마이페이지</b-nav-item>
        <b-nav-item class="px-3" @click.prevent="logout">로그아웃</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav v-show="this.user == null" class="fl mr-4">
        <b-nav-item class="px-3" to="/login">로그인</b-nav-item>
        <b-nav-item class="px-3" to="/register" exact>회원가입</b-nav-item>
      </b-navbar-nav>
    </AppHeader>
    <div class="app-body">
      <main class="main py-4">
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
      <TheFooter>
        <div>
          <p class="font-weight-bold">
            &copy; 2021 문자야. ALL RIGHTS RESERVED.
          </p>
        </div>
      </TheFooter>
    </div>
  </div>
</template>

<script>
import { Header as AppHeader, Footer as TheFooter } from '@coreui/vue'
import { mapGetters } from 'vuex'

export default {
  name: 'full',
  components: {
    AppHeader,
    TheFooter,
  },
  data() {
    return {
      statusTimer: null,
    }
  },
  methods: {
    goTo(path) {
      this.$router.push(path)
    },
  },
  mounted() {
    const token = this.$cookie.get('token')
    if (token) {
      this.getUserInfo()
    }
  },
  computed: {
    name() {
      return this.$route.name
    },
    user() {
      if (this.$store.state.user) {
        clearInterval(this.statusTimer)
        this.getStatus()

        this.statusTimer = setInterval(() => {
          this.getStatus()
        }, 60 * 1000)
      }

      return this.$store.state.user
    },
    list() {
      return this.$route.matched.filter(route => route.name || route.meta.label)
    },
    level() {
      if (!this.$store.state.user) {
        return 0
      }
      return this.$store.state.user.level
    },
  },
  watch: {
    user() {},
    level() {},
  },
}
</script>
<style>
.header-logo {
  display: flex;
  flex-flow: column;
  text-decoration: none !important;
}

.header-logo > span:first-of-type {
  color: #edc028;
  font-size: 27px;
  line-height: 28px;
  font-weight: 700;
}

.header-logo > span:nth-of-type(2) {
  color: #edc028;
  display: block;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: -2px;
}

.app-footer {
  flex: 0 0 80px;
}

.app-footer > div > p {
  margin-top: 8px;
  font-size: 13px;
  line-height: 13px;
  margin-bottom: 0;
  color: #7a7b7c;
}
</style>
